import React from 'react';
import './Hero.css';
import { HiLocationMarker } from "react-icons/hi";
// import CountUp from 'react-countup';
import CountUp from 'react-countup';
import { motion } from "framer-motion";
const Hero = () => {
  return (
    <section className='hero-wrapper bg-dark'>
      <div className='paddings innerWidth flexCenter hero-container'>

        <div className='flexColStart hero-left'>
            <div className="hero-title">
                <div className="orange-circle"/>
                <motion.h1
                initial={{y:"2rem", opacity: 0}}
                animate={{y:0, opacity:1}}
                transition={{
                    duration:2,
                    type: 'ease-in'
                }}
                >
                    Discover<br/> sutiable property<br/>
                </motion.h1>
            </div>
            <div className="flexColStart hero-des">
                <motion.span
                initial={{y:"2rem", opacity: 0}}
                animate={{y:0, opacity:1}}
                transition={{
                    duration:2,
                    type: 'ease-in'
                }}
                >
                    Find a variety of properties that suits you
                </motion.span>
                <motion.span
                initial={{y:"2rem", opacity: 0}}
                animate={{y:0, opacity:1}}
                transition={{
                    duration:2,
                    type: 'ease-in'
                }}
                >
                    Forget all the difficulties in finding a residences for you
                </motion.span>
            </div>
            <div className="search-bar ">
                <HiLocationMarker color="var(--blue)" size={25}/>
                <input type="text" />
                <button className='button' id='btn1'>Search</button>
            </div>

            <div className="flexCenter stats">
                <div className="flexColStart stat">
                    <span>
                        <CountUp start={8800} end={9000} duration={4}/>
                        <span>+</span>
                    </span>
                    <span className="secondaryText">Premium Products</span>
                </div>

                <div className="flexColStart stat">
                    <span>
                        <CountUp start={1800} end={2200} duration={4}/>
                        <span>+</span>
                    </span>
                    <span className="secondaryText">Happy Customers</span>
                </div>

                <div className="flexColStart stat">
                    <span>
                        <CountUp end={30} duration={4}/>
                        <span>+</span>
                    </span>
                    <span className="secondaryText">Award Wining</span>
                </div>
            </div>
        </div>
      


         {/* right side of the hero */}
            <div className='flexCenter hero-right'>
                <motion.div className='image-container'
                initial={{y:"7rem", opacity: 0}}
                animate={{y:0, opacity:1}}
                transition={{
                    duration:2,
                    type: 'ease-in'
                }}>
                    <img src="pictures/hero-image.png" alt="hero_image" id='img1'/>
                </motion.div>
            </div>

      </div>
      
      
    </section>
  )
}

export default Hero

