import React from "react";
import './Getstarted.css';
const Getstarted = () => {
  return (
    <section className="g-wrapper">
      <div className="paddings innerWidth g-container">
        <div className="flexColCenter inner-container">
          <span className="primaryText">Get Started with Homyz</span>
          <span className="secondaryText">subscribe and find super attractive price quotes from our dealers
            <br />
            Find Your own Residence now
          </span>
          <button className="button">
            <a href="mailto:navneetmishra2000@gmail.com">Get started</a>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Getstarted;
