import React from "react";
import './Companies.css'
const companies = () => {
  return (
    <section className="c-wrapper ">
      <div className="paddings innerWidth flexCenter c-container">
        <img src="pictures/realty.png" alt="Company1"  />
        <img src="pictures/tower.png" alt="Company2"  />
        <img src="pictures/equinix.png" alt="Company3"  />
        <img src="pictures/prologis.png" alt="Company4"  />
      </div>
    </section>
  );
};

export default companies;
