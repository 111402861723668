// import logo from './logo.svg';
import Header
 from './components/header/Header';
import './App.css';
import './components/header/Header.css';
import Hero from './components/hero/Hero';
import './components/hero/Hero.css';
import Companies from './components/companies/Companies';
import './components/companies/Companies.css';
import Residencies from "./components/residencies/Residencies";
import './components/residencies/Residencies.css';
import Contact from './components/contact/Contact';
import Getstarted from './components/getstarted/Getstarted';


function App() {
  return (
    <div className="App">
      {/* <h1>Hello111</h1> */}
      <Header/>
      <Hero/>
      <Companies/>
      <Residencies/>
      <Contact/>
      <Getstarted/>
    </div>
  );
}

export default App;
