import React from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "./Residencies.css";
import data from "../../utils/slider.json";
import { slidersettings } from "../../utils/common";
// import background from '../public/pictures/background.jpg';

const Residencies = () => {
  return (
    <section className="r-wrapper">
      <div className="paddingd innerWidth r-container" style={{
      backgroundImage: 'url("pictures/background2.jpg")',
      height: "450px", backgroundRepeat: "repeat"
    }}> 
        <div className="r-head flexColStart">

          <span className="orangeText orangetext2">Best Choices</span>
          <span className="primaryText orangetext2">Popular Residencies</span>
        </div>
        <Swiper {...slidersettings}>
          <SliderButton/>
          {data.map((card, i)=> (
            <SwiperSlide key={i}>
              <div className="flexColStart r-card bg-dark">
                <img src={card.image} alt="home" />
                <span className="secondaryText r-price">
                  <span style={{color:"orange"}}>$</span>
                  <span>{card.price}</span>
                </span>

                <span className="primaryText">{card.name}</span>
                <span className="secondaryText">{card.detail}</span>
              </div>
            </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Residencies;

const SliderButton = ()=>{
  const swiper = useSwiper();
  return(
    <div className="r-button">
      <button onClick={()=> swiper.slidePrev()}>&lt;</button>
      <button onClick={()=> swiper.slideNext()}>&gt;</button>
    </div>
  )
}
