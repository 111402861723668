import React from "react";
import "./Contact.css";
import { MdCall } from "react-icons/md";
import { BsChatDotsFill, BsFillChatDotsFill } from "react-icons/bs";
import { HiChatBubbleBottomCenter } from "react-icons/hi2";

const Contact = () => {
  return (
    <section className="c-wrapper">
      <div className="paddings innerWidth flexCenter c-container ">
        {/* left side */}
        <div className="flexColStart c-left">
          <span className="orangeText">Our Contacts</span>
          <span className="primaryText">Easy to contact us</span>
          <span className="secondaryText">
            We make your life comfertable and happy. Hope you find your dream
            house here.
          </span>

          <div className="flexColStart contactModes">
            {/* first row */}
            <div className="flexStart row ">
              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter icon">
                    {/* <MdCall size={25}/> */}
                  </div>
                  <div className="flexColStart details">
                    <span className="primaryText">
                      <MdCall size={25} />
                      &nbsp;Call
                    </span>
                    <span className="secondaryText">021 123 145 14</span>
                  </div>
                </div>
                <div className="flexCenter button">Call Now</div>
              </div>
              {/* second mode */}
              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter icon">
                    {/* <BsChatDotsFill size={25}/> */}
                  </div>
                  <div className="flexColStart details">
                    <span className="primaryText">
                      <BsChatDotsFill size={25} />
                      &nbsp;Chat
                    </span>
                    <span className="secondaryText">021 123 145 14</span>
                  </div>
                </div>
                <div className="flexCenter button">Chat Now</div>
              </div>
            </div>
            {/* second row */}
            <div className="flexStart row">
              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter icon">
                    {/* <MdCall size={25}/> */}
                  </div>
                  <div className="flexColStart details">
                    <span className="primaryText">
                      <BsFillChatDotsFill size={25} />
                      &nbsp;Video Call
                    </span>
                    <span className="secondaryText">021 123 145 14</span>
                  </div>
                </div>
                <div className="flexCenter button">Video Call Now</div>
              </div>
              {/* fourth mode */}
              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter icon">
                    {/* <BsChatDotsFill size={25}/> */}
                  </div>
                  <div className="flexColStart details">
                    <span className="primaryText">
                      <HiChatBubbleBottomCenter size={25} />
                      &nbsp; Message
                    </span>
                    <span className="secondaryText">021 123 145 14</span>
                  </div>
                </div>
                <div className="flexCenter button">Message Now</div>
              </div>
            </div>
          </div>
        </div>

        {/* right side */}
        <div className="c-right">
          <div className="image-container">
            <img src="pictures/contact.jpg" alt="contact" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
